import { inject, Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { catchError, of, tap } from 'rxjs';

import { EMPTY_PAGING, PagingOrder } from '@clover/core/helpers/paging';
import { UserService } from '@clover/core/services/user.service';
import { MetricsService } from '@clover/dashboard-v2/widgets/metrics/metrics.service';
import { PurchaseOrdersService } from '@clover/dashboard-v2/widgets/purchase-orders/purchase-orders.service';
import { QuickLinksService } from '@clover/dashboard-v2/widgets/quick-links/quick-links.service';
import { LoadDocuments } from '@clover/network-v2/company/company-wiki/state/wiki.actions';
import { FilterableTaskStatus, TaskScope, TaskType } from '@clover/tasks-v2/state/tasks.entities';
import { TasksService } from '@clover/tasks-v2/state/tasks.service';

import {
  LoadCompanySpecificDashboard,
  LoadCompanySpecificDashboardMetrics,
  LoadCompanySpecificDashboardPurchaseOrders,
  LoadCompanySpecificDashboardQuickLinks,
  LoadCompanySpecificDashboardReceivedTasks,
  LoadCompanySpecificDashboardSentTasks,
  LoadCompanySpecificDashboardWiki,
} from './company-specific-dashboard.actions';
import {
  CompanySpecificDashboardStateModel,
  DEFAULT_COMPANY_SPECIFIC_DASHBOARD_STATE,
} from './company-specific-dashboard.entities';

@State<CompanySpecificDashboardStateModel>({
  name: 'companySpecificDashboard',
  defaults: DEFAULT_COMPANY_SPECIFIC_DASHBOARD_STATE,
})
@Injectable()
export class CompanySpecificDashboardState {
  private readonly quickLinksService = inject(QuickLinksService);
  private readonly metricsService = inject(MetricsService);
  private readonly tasksService = inject(TasksService);
  private readonly purchaseOrdersService = inject(PurchaseOrdersService);

  private readonly userService = inject(UserService);

  get permissions() {
    return this.userService.permissions;
  }

  @Action(LoadCompanySpecificDashboard)
  loadCompanySpecificDashboard(
    ctx: StateContext<CompanySpecificDashboardStateModel>,
    { companyId }: LoadCompanySpecificDashboard,
  ) {
    return ctx
      .dispatch(
        [
          new LoadCompanySpecificDashboardQuickLinks(companyId),
          new LoadCompanySpecificDashboardWiki(companyId),
          new LoadCompanySpecificDashboardMetrics(companyId),
          this.permissions.Task_ViewAssigned ? new LoadCompanySpecificDashboardReceivedTasks(companyId) : undefined,
          this.permissions.Task_Assign ? new LoadCompanySpecificDashboardSentTasks(companyId) : undefined,
          new LoadCompanySpecificDashboardPurchaseOrders(companyId),
        ].filter(Boolean),
      )
      .pipe(tap(() => ctx.patchState({ companyId })));
  }

  @Action(LoadCompanySpecificDashboardQuickLinks)
  loadCompanySpecificDashboardQuickLinks(
    ctx: StateContext<CompanySpecificDashboardStateModel>,
    { companyId }: LoadCompanySpecificDashboardQuickLinks,
  ) {
    ctx.patchState({
      quickLinksLoadingStatus: 'loading',
    });

    return this.quickLinksService.getQuickLinks(companyId, { limit: 2499 }).pipe(
      tap((quickLinks) => {
        ctx.patchState({
          quickLinks,
          quickLinksLoadingStatus: 'loaded',
        });
      }),
      catchError((error) => {
        ctx.patchState({
          quickLinks: EMPTY_PAGING,
          quickLinksLoadingStatus: 'error',
        });
        return of(error);
      }),
    );
  }

  @Action(LoadCompanySpecificDashboardWiki)
  loadCompanySpecificDashboardWiki(
    ctx: StateContext<CompanySpecificDashboardStateModel>,
    { companyId }: LoadCompanySpecificDashboardWiki,
  ) {
    return ctx.dispatch(new LoadDocuments(companyId)).pipe(
      tap(() => {
        ctx.patchState({
          wikiLoadingStatus: 'loaded',
        });
      }),
      catchError((error) => {
        ctx.patchState({
          wikiLoadingStatus: 'error',
        });
        return of(error);
      }),
    );
  }

  @Action(LoadCompanySpecificDashboardMetrics)
  loadCompanySpecificDashboardMetrics(
    ctx: StateContext<CompanySpecificDashboardStateModel>,
    { companyId }: LoadCompanySpecificDashboardMetrics,
  ) {
    ctx.patchState({
      metricsLoadingStatus: 'loading',
    });

    return this.metricsService.getMetrics(companyId, { limit: 2499 }).pipe(
      tap((metrics) => {
        ctx.patchState({ metrics, metricsLoadingStatus: 'loaded' });
      }),
      catchError((error) => {
        ctx.patchState({ metrics: EMPTY_PAGING, metricsLoadingStatus: 'error' });
        return of(error);
      }),
    );
  }

  @Action(LoadCompanySpecificDashboardSentTasks)
  loadCompanySpecificDashboardSentTasks(
    ctx: StateContext<CompanySpecificDashboardStateModel>,
    {
      companyId,
      scope = DEFAULT_COMPANY_SPECIFIC_DASHBOARD_STATE.sentTasksScope,
    }: LoadCompanySpecificDashboardSentTasks,
  ) {
    const state = ctx.getState();
    if (companyId !== state.companyId) {
      ctx.patchState({ sentTasksLoadingStatus: 'loading' });
    }

    return this.tasksService
      .searchTasks(
        {
          type: TaskType.Sent,
          status: FilterableTaskStatus.Incomplete,
          relatedToUserId: scope === TaskScope.My ? this.userService.userProfile.id : undefined,
          relatedToCompanyId: companyId,
        },
        {
          limit: 5,
          order: PagingOrder.Descending,
          orderBy: 'createdAt',
        },
      )
      .pipe(
        tap((sentTasks) => {
          ctx.patchState({ sentTasks, sentTasksLoadingStatus: 'loaded', sentTasksScope: scope });
        }),
        catchError((error) => {
          ctx.patchState({ sentTasks: EMPTY_PAGING, sentTasksLoadingStatus: 'error' });
          return of(error);
        }),
      );
  }

  @Action(LoadCompanySpecificDashboardReceivedTasks)
  loadCompanySpecificDashboardReceivedTasks(
    ctx: StateContext<CompanySpecificDashboardStateModel>,
    {
      companyId,
      scope = DEFAULT_COMPANY_SPECIFIC_DASHBOARD_STATE.receivedTasksScope,
    }: LoadCompanySpecificDashboardReceivedTasks,
  ) {
    const state = ctx.getState();
    if (companyId !== state.companyId) {
      ctx.patchState({ receivedTasksLoadingStatus: 'loading' });
    }

    return this.tasksService
      .searchTasks(
        {
          type: TaskType.Received,
          status: FilterableTaskStatus.Incomplete,
          relatedToUserId: scope === TaskScope.My ? this.userService.userProfile.id : undefined,
          relatedToCompanyId: companyId,
        },
        {
          limit: 5,
          order: PagingOrder.Descending,
          orderBy: 'createdAt',
        },
      )
      .pipe(
        tap((receivedTasks) => {
          ctx.patchState({ receivedTasks, receivedTasksLoadingStatus: 'loaded', receivedTasksScope: scope });
        }),
        catchError((error) => {
          ctx.patchState({ receivedTasks: EMPTY_PAGING, receivedTasksLoadingStatus: 'error' });
          return of(error);
        }),
      );
  }

  @Action(LoadCompanySpecificDashboardPurchaseOrders)
  loadCompanySpecificDashboardPurchaseOrders(
    ctx: StateContext<CompanySpecificDashboardStateModel>,
    { companyId }: LoadCompanySpecificDashboardPurchaseOrders,
  ) {
    ctx.patchState({
      purchaseOrdersLoadingStatus: 'loading',
    });

    return this.purchaseOrdersService.getPurchaseOrders(companyId, { limit: 2499 }).pipe(
      tap((purchaseOrders) => {
        ctx.patchState({ purchaseOrders, purchaseOrdersLoadingStatus: 'loaded' });
      }),
      catchError((error) => {
        ctx.patchState({ purchaseOrders: EMPTY_PAGING, purchaseOrdersLoadingStatus: 'error' });
        return of(error);
      }),
    );
  }
}
