import type { TaskScope } from '@clover/tasks-v2/state/tasks.entities';

export class LoadCompanySpecificDashboard {
  static readonly type = '[Company Specific Dashboard] Load Company Specific Dashboard';

  constructor(public readonly companyId: number) {}
}

export class LoadCompanySpecificDashboardQuickLinks {
  static readonly type = '[Company Specific Dashboard] Load Company Specific Dashboard Quick Links';

  constructor(public readonly companyId: number) {}
}

export class LoadCompanySpecificDashboardWiki {
  static readonly type = '[Company Specific Dashboard] Load Company Specific Dashboard Wiki';

  constructor(public readonly companyId: number) {}
}

export class LoadCompanySpecificDashboardMetrics {
  static readonly type = '[Company Specific Dashboard] Load Company Specific Dashboard Metrics';

  constructor(public readonly companyId: number) {}
}

export class LoadCompanySpecificDashboardReceivedTasks {
  static readonly type = '[Company Specific Dashboard] Load Company Specific Dashboard Received Tasks';

  constructor(
    public readonly companyId: number,
    public readonly scope?: TaskScope,
  ) {}
}

export class LoadCompanySpecificDashboardSentTasks {
  static readonly type = '[Company Specific Dashboard] Load Company Specific Dashboard Sent Tasks';

  constructor(
    public readonly companyId: number,
    public readonly scope?: TaskScope,
  ) {}
}

export class LoadCompanySpecificDashboardPurchaseOrders {
  static readonly type = '[Company Specific Dashboard] Load Company Specific Dashboard Purchase Orders';

  constructor(public readonly companyId: number) {}
}
