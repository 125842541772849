import type { LoadingStatus } from '@clover/core/helpers/loading';
import { EMPTY_PAGING, type OffsetPagingWrapper } from '@clover/core/helpers/paging';
import type { Metric } from '@clover/dashboard-v2/widgets/metrics/metrics.service';
import type { PurchaseOrder } from '@clover/dashboard-v2/widgets/purchase-orders/purchase-orders.entities';
import type { QuickLink } from '@clover/dashboard-v2/widgets/quick-links/quick-links.service';
import { TaskPreview, TaskScope } from '@clover/tasks-v2/state/tasks.entities';

export interface CompanySpecificDashboardStateModel {
  companyId: number;

  quickLinks: OffsetPagingWrapper<QuickLink>;
  quickLinksLoadingStatus: LoadingStatus;

  wikiLoadingStatus: LoadingStatus;

  metrics: OffsetPagingWrapper<Metric>;
  metricsLoadingStatus: LoadingStatus;

  receivedTasks: OffsetPagingWrapper<TaskPreview>;
  receivedTasksScope: TaskScope;
  receivedTasksLoadingStatus: LoadingStatus;

  sentTasks: OffsetPagingWrapper<TaskPreview>;
  sentTasksScope: TaskScope;
  sentTasksLoadingStatus: LoadingStatus;

  purchaseOrders: OffsetPagingWrapper<PurchaseOrder>;
  purchaseOrdersLoadingStatus: LoadingStatus;
}

export const DEFAULT_COMPANY_SPECIFIC_DASHBOARD_STATE: CompanySpecificDashboardStateModel = {
  companyId: undefined,

  quickLinks: EMPTY_PAGING,
  quickLinksLoadingStatus: 'void',

  wikiLoadingStatus: 'void',

  metrics: EMPTY_PAGING,
  metricsLoadingStatus: 'void',

  receivedTasks: EMPTY_PAGING,
  receivedTasksScope: TaskScope.My,
  receivedTasksLoadingStatus: 'void',

  sentTasks: EMPTY_PAGING,
  sentTasksScope: TaskScope.All,
  sentTasksLoadingStatus: 'void',

  purchaseOrders: EMPTY_PAGING,
  purchaseOrdersLoadingStatus: 'void',
};
