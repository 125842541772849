import { Selector } from '@ngxs/store';

import { mergeLoadingStatuses } from '@clover/core/helpers/loading';

import type { CompanySpecificDashboardStateModel } from './company-specific-dashboard.entities';
import { CompanySpecificDashboardState } from './company-specific-dashboard.state';

export class CompanySpecificDashboardSelectors {
  @Selector([CompanySpecificDashboardState])
  static loadingStatus(state: CompanySpecificDashboardStateModel) {
    return mergeLoadingStatuses(
      state.quickLinksLoadingStatus,
      state.wikiLoadingStatus,
      state.metricsLoadingStatus,
      state.receivedTasksLoadingStatus,
      state.sentTasksLoadingStatus,
      state.purchaseOrdersLoadingStatus,
    );
  }

  @Selector([CompanySpecificDashboardState])
  static quickLinks(state: CompanySpecificDashboardStateModel) {
    return state.quickLinks;
  }

  @Selector([CompanySpecificDashboardState])
  static metrics(state: CompanySpecificDashboardStateModel) {
    return state.metrics;
  }

  @Selector([CompanySpecificDashboardState])
  static receivedTasks(state: CompanySpecificDashboardStateModel) {
    return state.receivedTasks;
  }

  @Selector([CompanySpecificDashboardState])
  static receivedTasksScope(state: CompanySpecificDashboardStateModel) {
    return state.receivedTasksScope;
  }

  @Selector([CompanySpecificDashboardState])
  static sentTasks(state: CompanySpecificDashboardStateModel) {
    return state.sentTasks;
  }

  @Selector([CompanySpecificDashboardState])
  static sentTasksScope(state: CompanySpecificDashboardStateModel) {
    return state.sentTasksScope;
  }

  @Selector([CompanySpecificDashboardState])
  static purchaseOrders(state: CompanySpecificDashboardStateModel) {
    return state.purchaseOrders;
  }
}
